<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-col class="page admin-teleconsultants">

    <!-- Tableau -->
    <vx-card>
      <div id="data-list-list-view" class="data-list-container">
        <vs-table :data="Teleconseillers">
          <template slot="thead">

            <vs-th>Nom</vs-th>
            <vs-th>Prénom</vs-th>
            <vs-th>Réseau</vs-th>
            <vs-th>Total Commandes</vs-th>
            <vs-th>Total Commandes en cours</vs-th>
            <vs-th>Total Rendez-vous</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].firstName">
                {{ data[indextr].firstName }}
              </vs-td>

              <vs-td :data="data[indextr].lastName">
                {{ data[indextr].lastName }}
              </vs-td>
              <vs-td :data="data[indextr].network">
                {{ data[indextr].network }}
              </vs-td>
              <vs-td :data="data[indextr].commandes">
                {{ data[indextr].commandes.length }}
              </vs-td>
              <vs-td :data="data[indextr].commandesEc">
                {{data[indextr].commandesEc }}
              </vs-td>
              <vs-td :data="data[indextr].rendezVous">
                {{ data[indextr].rendezVous.length }}
              </vs-td>

            </vs-tr>
          </template>

        </vs-table>
      </div>
    </vx-card>
    <vx-card title="Détails des rendez-vous par téléconseiller" class="mt-5">
      <div slot="no-body">
        <!-- CHART -->
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <label><small>Par date de création</small></label>
            <flat-pickr :config="configdateTimePicker" placeholder="Date début"
                        v-model="dateDebut"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <flat-pickr :config="configdateTimePicker" placeholder="Date fin" v-model="dateFin"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="2" vs-sm="12" vs-xs="12"
                  class="space-10">
            <label><small>Formule</small></label>
            <select class="vs-select vx-col w-12/12" v-model="formuleTc">
              <option value="" selected></option>
              <option v-for="item in formules" :key="item" :value="item">{{item}}</option>
            </select>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <vs-button class="mr-3 mb-2" @click="getNbRendezVous()">Filtrer</vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-lg="4">
            <vue-apex-charts type="pie" height="350" :options="adminAnalytics.totalTcChart.chartOptions"
                             :series="adminAnalytics.totalTcChart.series"></vue-apex-charts>
          </vs-col>
          <vs-col vs-lg="8">
            <vue-apex-charts ref="chart" type="bar" height="350" class="my-12"
                             :options="adminAnalytics.TcChart.chartOptions" auto-resize
                             :series="adminAnalytics.TcChart.series"></vue-apex-charts>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>

    <vx-card title="Rendez-vous réalisés et mandats" class="mt-5">
      <div slot="no-body">
        <!-- CHART -->
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-xs="12"
                  class="space-10">
            <label><small>Par date de création</small></label>
            <flat-pickr :config="configdateTimePicker" placeholder="Date début"
                        v-model="dateDebutMandat"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <flat-pickr :config="configdateTimePicker" placeholder="Date fin" v-model="dateFinMandat"/>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="2" vs-sm="12" vs-xs="12"
                  class="space-10">
            <label><small>Formule</small></label>
            <select class="vs-select vx-col w-12/12" v-model="formule">
              <option value="" selected></option>
              <option v-for="item in formules" :key="item" :value="item">{{item}}</option>
            </select>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <vs-button class="mr-3 mb-2" @click="getMandats()">Filtrer</vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-lg="4">
            <vue-apex-charts type="pie" height="350" :options="adminAnalytics.totalRdvRealiseChart.chartOptions"
                             :series="adminAnalytics.totalRdvRealiseChart.series"></vue-apex-charts>
          </vs-col>
          <vs-col vs-lg="8">
            <vue-apex-charts ref="chart" type="bar" height="350" :options="adminAnalytics.barChartMandat.chartOptions"
                             :series="adminAnalytics.barChartMandat.series"></vue-apex-charts>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
    <vx-card title="Demandes d'annulation par Téleconseiller" class="mt-5">
      <div slot="no-body">
        <!-- CHART -->
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <label><small>Par date de création</small></label>
            <flat-pickr :config="configdateTimePicker" placeholder="Date début"
                        v-model="dateDebutDemande"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <flat-pickr :config="configdateTimePicker" placeholder="Date fin" v-model="dateFinDemande"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <select class="vs-select vx-col w-12/12" v-model="statutDemande">
              <option value="" selected>Etat</option>
              <option v-for="item in listeStatus" :key="item" :value="item">{{item}}</option>
            </select>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12"
                  class="space-10">
            <vs-button class="mr-3 mb-2" @click="getDemandesAnnulations()">Filtrer</vs-button>
          </vs-col>
        </vs-row>
        <!-- <e-charts autoresize :options="adminAnalytics.TcChart" ref="bar" theme="ovilia-green" auto-resize />-->
        <vue-apex-charts ref="chart" type="bar" height="350"
                         :options="adminAnalytics.annulationsTcChart.chartOptions"
                         :series="adminAnalytics.annulationsTcChart.series"></vue-apex-charts>
      </div>
    </vx-card>
  </vs-col>
</template>

<script>
import axios from '@/axios';
// import {colors} from "../../../themeConfig";
// import store from "@/store/store";
import moment from 'moment';
// import router from '@/router';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { AgGridVue } from 'ag-grid-vue';

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
// let user = store.state.AppActiveUser.user
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueApexCharts from 'vue-apexcharts';
import ECharts from 'vue-echarts/components/ECharts';
import adminAnalytics from './adminAnalytics.js';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';

export default {
  components: {
    ECharts,
    swiper,
    swiperSlide,
    AgGridVue,
    VueApexCharts,
    flatPickr,
  },
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
  },
  data() {
    return {
      statutDemande: '',
      annee: new Date().getFullYear(),
      dateDebut: '',
      dateFin: '',
      formules: [],
      listeStatus: ['A traiter', 'Validé', 'Refusé'],
      formule: '',
      formuleTc: '',
      dateDebutMandat: '',
      dateFinMandat: '',
      format: 'dd/MM/yyyy',
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d',
      },
      dateDebutDemande: '',
      dateFinDemande: '',
      Teleconseillers: '',
      optionsTeleconseiller: [],
      optionsMotifs: [],
      adminAnalytics,

    };
  },

  methods: {
    getNbRendezVous() {
      const url = `/api/rendez_vous/teleconseiller?dateDebut=${this.dateDebut}&dateFin=${this.dateFin}&formule=${this.formuleTc}`;
      this.activeLoading = true;
      this.$vs.loading({
        type: 'point',
      });
      axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        let labels = [];
        labels = response.data.OneByOne.nomPrenom;
        this.adminAnalytics.TcChart.series[0].data = response.data.OneByOne.enAttenteAnnul;
        this.adminAnalytics.TcChart.series[1].data = response.data.OneByOne.aVenir;
        this.adminAnalytics.TcChart.series[2].data = response.data.OneByOne.annule;
        this.adminAnalytics.TcChart.series[3].data = response.data.OneByOne.aStatuer;
        this.adminAnalytics.TcChart.series[4].data = response.data.OneByOne.termine;
        this.adminAnalytics.TcChart.chartOptions = {
          ...this.adminAnalytics.TcChart.chartOptions,
          ...{
            xaxis: {
              categories: labels,
            },
          },
        };

        this.adminAnalytics.totalTcChart.series = response.data.Totals.totals;
        // this.adminAnalytics.totalTcChart.series[0].data  = response.data['Totals'][0].totals
        this.adminAnalytics.totalTcChart.chartOptions = {
          ...this.adminAnalytics.totalTcChart.chartOptions,
          ...{
            labels: response.data.Totals.status,
          },
        };
        this.activeLoading = false;
        this.$vs.loading.close();
      })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page').goAway(1500);
        });
    },
    getMandats() {
      const url = `/api/rendez_vous/mandats?dateDebut=${this.dateDebutMandat}&dateFin=${this.dateFinMandat}&formule=${this.formule}`;
      axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        let labels = [];
        labels = response.data.nomPrenom;
        this.adminAnalytics.barChartMandat.series[0].data = response.data.rdvRealise;
        this.adminAnalytics.barChartMandat.series[1].data = response.data.nbMandats;
        this.adminAnalytics.barChartMandat.chartOptions.xaxis.categories = labels;
        this.adminAnalytics.barChartMandat.chartOptions = {
          ...this.adminAnalytics.barChartMandat.chartOptions,
          ...{
            yaxis: {
              categories: labels,
            },
          },
        };
        this.adminAnalytics.totalRdvRealiseChart.series = response.data.Totals.totals;
        this.adminAnalytics.totalRdvRealiseChart.chartOptions = {
          ...this.adminAnalytics.totalRdvRealiseChart.chartOptions,
          ...{
            labels: response.data.Totals.status,
          },
        };
      })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page').goAway(1500);
        });
    },
    getDemandesAnnulations() {
      const url = `/api/demandes/teleconseiller?dateDebut=${this.dateDebutDemande}&dateFin=${this.dateFinDemande}&statut=${this.statutDemande}`;
      axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        let labels = [];
        labels = response.data.nomPrenom;
        // console.log(this.adminAnalytics.annulationsTcChart.series);
        let i = 0;
        let j = 0;
        response.data.motifs.forEach((r) => {
          this.adminAnalytics.annulationsTcChart.series[j].name = r;
          j++;
        });
        response.data.totals.forEach((x) => {
          this.adminAnalytics.annulationsTcChart.series[i].data = x;
          i++;
        });
        this.adminAnalytics.annulationsTcChart.chartOptions.xaxis.categories = labels;
        this.adminAnalytics.annulationsTcChart.chartOptions = {
          ...this.adminAnalytics.annulationsTcChart.chartOptions,
          ...{
            yaxis: {
              categories: labels,
            },
          },
        };
      })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page').goAway(1500);
        });
    },
    getTeleconseillers() {
      // Liste des téleconseillers
      axios.get('/api/employees?serviceId=11&enabled=true', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          const arrayTeleconseillers = [];

          const arrayTeleproData = [];
          response.data.forEach((key) => {
            let cmdEc = 0;
            let teleconseiller = '';
            teleconseiller = key;
            if (key.commandes !== null) {
              key.commandes.forEach((value) => {
                if (value.statut == 'EC') {
                  cmdEc++;
                }
              });
            }
            teleconseiller.commandesEc = cmdEc;
            arrayTeleconseillers.push({ label: `${key.firstName} ${key.lastName}`, value: key.id });
            arrayTeleproData.push(teleconseiller);
          });
          this.optionsTeleconseiller = arrayTeleconseillers;
          this.Teleconseillers = arrayTeleproData;
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
    getFormules() {
      const url = '/api/getFormulesNames';
      axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        this.formules = response.data;
      })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page').goAway(1500);
        });
    },

  },
  mounted() {
    this.getTeleconseillers();
  },
  created() {
    this.getNbRendezVous();
    this.getMandats();
    this.getFormules();
    this.getDemandesAnnulations();
  },
};
</script>
<style>
  .admin-teleconsultants .vs-table--tbody-table .vs-table--thead tr {
    background: #ed1e54;
    color: #fff;
  }

  .admin-teleconsultants.vs-table--tbody-table .vs-table--thead tr .vs-table-text {
    text-transform: uppercase;
    font-weight: 600;
  }
</style>
